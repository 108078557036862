<!-- 订单信息 -->
<template>
  <div>
    <back />
    <div class="orderDetail" v-loading="loading">
      <h3 class="title">订单信息：</h3>
      <!-- 线上订单 -->
      <div v-if="detail?.orderType == '1'">
        <el-row class="row">
          <el-col :span="7">
            <div class="col">订单号：{{ detail?.orderNo }}</div>
            <div class="col">订单金额：<span v-if="detail?.paymentPrice">{{ detail?.paymentPrice }}元</span></div>
            <div class="col">商超名称：{{ detail?.shopName }}</div>
            <div class="col">买家姓名：{{ detail?.buyerName }}</div>
            <div class="col">骑手姓名：{{ detail?.riderName }}</div>
          </el-col>
          <el-col :span="7">
            <div class="col">下单时间：{{ detail?.orderTime }}</div>
            <div class="col">优惠金额：<span v-if="detail?.discountAmount">{{ detail?.discountAmount }}元</span></div>
            <div class="col">商超电话：{{ detail?.shopPhone }}</div>
            <div class="col">买家电话：{{ detail?.buyPhone }}</div>
            <div class="col">骑手电话：{{ detail?.riderPhone }}</div>
          </el-col>
          <el-col :span="7">
            <div class="col">完成时间：{{ detail?.completionTime }}</div>
            <div class="col">配送费：<span v-if="detail?.freightPrice">{{ detail?.freightPrice }}元</span></div>
            <div class="col">校区：{{ detail?.school }}</div>
            <div class="col">买家地址：{{ detail?.buyAddress }}</div>
            <div class="col">骑手编号：{{ detail?.riderNum }}</div>
          </el-col>
          <el-col :span="3">
            <div class="col">订单类型：{{ detail?.orderType == '1' ? '线上订单' : '线下订单' }}</div>
          </el-col>
        </el-row>
      </div>

      <!-- 线下订单 -->
      <div v-if="detail?.orderType == '0'">
        <el-row class="row">
          <el-col :span="7">
            <div class="col">订单号：{{ detail?.orderNo }}</div>
            <div class="col">商超名称：{{ detail?.shopName }}</div>
          </el-col>
          <el-col :span="7">
            <div class="col">下单时间：{{ detail?.orderTime }}</div>
            <div class="col">商超电话：{{ detail?.shopPhone }}</div>
          </el-col>
          <el-col :span="7">
            <div class="col">完成时间：{{ detail?.completionTime }}</div>
            <div class="col">校区：{{ detail?.school }}</div>
          </el-col>
          <el-col :span="3">
            <div class="col">订单类型：{{ detail?.orderType == '1' ? '线上订单' : '线下订单' }}</div>
          </el-col>
        </el-row>
      </div>

      <!-- 商品信息 -->
      <h3 class="title">商品信息：</h3>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="220">
          <el-table-column type="index" label="序号"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品条形码"></el-table-column> -->
          <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column show-overflow-tooltip prop="expirationDay" label="保质期(天)"></el-table-column>
          <el-table-column show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productPrice" label="单价(元)"></el-table-column>
          <el-table-column show-overflow-tooltip prop="unitQuantity" label="数量"></el-table-column>
          <el-table-column show-overflow-tooltip prop="totalPrice" label="金额(元)"></el-table-column>
        </el-table>
      </div>
      <!-- 实付金额 -->
      <div class="pay-fee">实付金额：<span v-if="detail?.paymentPrice">{{ detail?.paymentPrice }}元</span></div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
      tableData: [],
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getSaleOrderInfo();
  },
  methods: {
    getSaleOrderInfo() {
      this.loading = true;
      // this.$axios.get(`${this.$api.getSaleOrderInfo}/${this.id}`).then((res) => {
      this.$axios.get(this.$api.getSaleOrderInfo, {
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
          this.tableData = res.data.result.saleOrderProductVos;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  }

}

</script>

<style lang="scss" scoped>
.orderDetail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
    margin-right: 10px;
  }

  // 实付金额
  .pay-fee {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    color: red;
  }
}
</style>